import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
import HubspotForm from "react-hubspot-form"

const Contact = ({ location }) => {
  return (
    <Layout>
      <Seo
        pagetitle="お問い合わせ"
        pagepath={location.pathname}
        pagedesc="お問い合わせ | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
      />
      <Breadcrumb title="お問い合わせ" />
      <main className="information">
        <section className="information__section l-container">
          {/* <h1 className="information__title"></h1> */}
          <HubspotForm
            portalId="8283520"
            formId="d4415218-40bf-440a-ad24-de15f15a8675"
            loading={<div>読み込んでいます...</div>}
          />
        </section>
      </main>
    </Layout>
  )
}

export default Contact
